@import "../node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: "BubbleboddyNeue";
  src: url("assets/fonts/BubbleboddyNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BubbleboddyNeue";
  src: url("assets/fonts/BubbleboddyNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "BubbleboddyNeue";
  src: url("assets/fonts/BubbleboddyNeue-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
}

#main-container {
  background: url("assets/images/background.png");
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

#main-container.login {
  background: url("assets/images/login/background.png");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'BubbleboddyNeue', 'Roboto', 'Ubuntu', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #bef2fd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.navbar {
  width: 100%;
}

.navbar-brand {
  padding: 16px;
}

.navbar-light {
  background-color: white!important;
}

.navbar-light .navbar-nav .nav-link, .nav-item.dropdown .dropdown-item  {
  color: #e4161b;
}

.navbar-light .navbar-nav .nav-link {
  text-transform: uppercase;
  font-weight: bold;
}

.nav-link, .nav-item.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item.dropdown .dropdown-item:hover, .nav-item.dropdown .dropdown-item:focus {
  color: #1e2125;
}


.btn-amarelo {
  color: #FFF;
  background-color: #ffc100;
  border-radius: 15px;
  font-size: 20px;
  font-weight: bold;
  font-family: "BubbleboddyNeue";
  box-shadow: 0 3px 5px rgba(0,0,0,.3);
}

.btn-amarelo:hover {
  color: #af5107;
}

.correct-answer {
  background-color: #007a00!important;
  animation: blink 1.3s linear forwards;
}

.wrong-answer {
  background-color: #ff0000!important;
  animation: blink 1.3s linear forwards; 
}

@keyframes blink {
  0%, 40%, 80% {
    opacity: 0;
  }

  20%, 60%, 100% {
    opacity: 1; 
  }

}

@media (max-width: 1023px) {
  .navbar {
    top: 0;
    position: absolute;
    z-index: 1001;
  }

  .navbar-brand {
    margin-left: -45px;
  }

  .nav-item {
    flex-direction: column;
  }

  .dropdown-menu {
    text-align: center;
  }
}
